import { toast } from "react-toastify";
import {
  accessTokenStore,
  seamlessAccessToken,
  seamlessRefreshToken,
} from "../store/accessToken";
import { userDataStore } from "../store/userData";
import storage from "./storage";

export const parseError = (error: any) => {
  const msg = error.response?.data.message;
  if (msg) toast.error(msg);
  else toast.error(error.response?.data.error);
};

export const downloadPdf = (pdfFile: any[], filename: string) => {
  const url = window.URL.createObjectURL(
    new Blob([new Uint8Array(pdfFile).buffer])
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

export const digitsOnly = (value?: string) =>
  value ? /^\d+$/.test(value) : false;

export const optionalDigitsOnly = (value?: string) =>
  value ? /^\d+$/.test(value) : true; //when the field is not compulsory

export const nonZeroDigitsOnly = (value?: string) =>
  value && Number(value) === 0 ? false : true;

export const loginFailed = () => {
  accessTokenStore.accessToken = undefined;
  accessTokenStore.refreshToken = undefined;
  userDataStore.userData = undefined;
  storage.remove(seamlessAccessToken);
  storage.remove(seamlessRefreshToken);
};

export const onStartChat = () => {
  window.open(
    "https://messenger.yablo.io/seamlessvisa.com/gubijgvxcptlfje",
    "window",
    "width=500, height=750, toolbar=no, top=100, left=100, menubar=no, resizable=yes"
  );
};

export const truncateTxt = (value: string, length: number) => {
  return value.length > length ? `${value.substring(0, length)}...` : value;
};

export function replaceEmptyPTagWithBrTag(htmlString: string) {
  return htmlString.replace(/<p><\/p>/gi, "<br/>");
}
export function bytesForHuman(sizeBytes: number | bigint): string {
  const UNITS = [
    "byte",
    "kilobyte",
    "megabyte",
    "gigabyte",
    "terabyte",
    "petabyte",
  ];
  const BYTES_PER_KB = 1000;
  let size = Math.abs(Number(sizeBytes));

  let u = 0;
  while (size >= BYTES_PER_KB && u < UNITS.length - 1) {
    size /= BYTES_PER_KB;
    ++u;
  }
  return new Intl.NumberFormat([], {
    style: "unit",
    unit: UNITS[u],
    unitDisplay: "short",
    maximumFractionDigits: 1,
  }).format(size);
}

export const getImageDimensions = async (file: File) => {
  const img = new Image();
  img.src = URL.createObjectURL(file);
  await img.decode();
  const width = img.width;
  const height = img.height;
  return {
    width,
    height,
  };
};

export const convertToLowercaseDashed = (text: string): string => {
  return text.toLowerCase().replace(/-/g, "");
};

export const phoneRegex =
  // eslint-disable-next-line no-useless-escape
  /^(\+?([1-9]\d{0,2}))?\(?(\d{3})\)?[-\s\.]?(\d{3})[-\s\.]?(\d{4})$/;

// standard format
// // /^\+?([1-9]\d{0,2})?\s?(?:(?:\(\d{3}\))|(\d{3}))?[-\s\.]\d{3}[-\s\.]\d{4}$/;

const devText = "development";

export const isDev =
  process.env.NODE_ENV === devText || process.env.REACT_APP_ENV === devText;

export const createLinkForDownload = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "DS-160 Form");
  document.body.appendChild(link);
  link.click();
};

export const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const ttsAudioConfig = {
  audioEncoding: "LINEAR16",
  pitch: 0,
  speakingRate: 1,
};

// more human-like voice
export const maleTTSVoiceConfig = {
  languageCode: "en-US",
  name: "en-US-Polyglot-1",
};

export const ttsVoiceConfig = {
  languageCode: "en-US",
  name: "en-US-Neural2-F",
};
